import React, { useState } from 'react';
import arnastofnunLogo from '../img/arnastofnun-logo.svg';
import arnastofnunLogoWhite from '../img/arnastofnun-logo-white.svg';

function SiteFooter(props) {
	return (
		<div className={'site-footer'+(props.className ? ' '+props.className : '')}>

			<div className="logo">
				<img src={arnastofnunLogo} className="dark" />
				<img src={arnastofnunLogoWhite} className="light" />
			</div>

			<p className="larger">Stofnun Árna Magnússonar<br/>í íslenskum fræðum</p>

			<p>Skrifstofa Árnagarði við<br/>Suðurgötu, 102 Reykjavík</p>
			<p><a href="mailto:arnastofnun@arnastofnun.is">arnastofnun@arnastofnun.is</a></p>
			<p>+354 525 4010</p>

		</div>
	);
}

export default SiteFooter;
