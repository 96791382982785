import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

function SubpageMenuButtons(props) {
	return <React.Fragment>
		<Link className="front-menu-button" to={'/'}><span>Forsíða</span></Link>
		<Link className="chapter-menu-button" to={props.chapterLink}><span>{props.chapterButtonLabel}</span></Link>
	</React.Fragment>;
}


export default SubpageMenuButtons;
