import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';

class Spurningar extends React.Component {
	constructor(props) {
		super(props);

		this.element = React.createRef();

		this.state = {
			currentQuestion: 0,
			answerIndex: -1
		};

		this.questionLetters = [
			'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'
		]
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
		if (this.props.chapterData.id != prevProps.chapterData.id) {
			this.setState({
				currentQuestion: 0,
				answerIndex: -1,
				message: null,
				rightAnswer: null
			})
		}
	}

	evaluateAnswer(answer, index) {
		this.setState({
			// birtum texta fyrir svarið, annars finnum við rétt svar
			message: answer.texti ? answer.texti : _.findWhere(this.props.chapterData.spurningar[this.state.currentQuestion].svor, {rett: true}).svar,
			rightAnswer: answer.rett,
			answerIndex: index
		});
	}

	render() {
		let colorRight = '#99AD20';
		let colorWrong = '#E75E3E';
		let baseColor = '#503D32';

		let question = this.props.chapterData.spurningar[this.state.currentQuestion];

		let dividerColor = this.state.message ? (this.state.rightAnswer ? colorRight.replace('#', '%23') : colorWrong.replace('#', '%23')) : (this.props.chapterData.einkennislitur_medium || baseColor).replace('#', '%23')

		let dividerBackground = "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 5.3377044 132.29166'><path d='M 9.9999998e-8,0 H 0.9851967 L 0.632419,2.4694444 4.7951968,24.553333 l -2.751667,20.249443 -0.282222,17.78 2.116667,14.957779 -0.141111,4.797777 -1.552222,14.74611 1.128888,18.344448 1.340556,7.90222 0.683519,9.12196 H 0 Z' fill='"+dividerColor+"' /></svg>\")";

		return <div ref={this.element} className="scroll-section app-content chapter question"
			style={{
				backgroundColor: this.state.message ? (this.state.rightAnswer ? colorRight : colorWrong) : (this.props.chapterData.einkennislitur_medium || baseColor)
			}}
		>
			<div className="app-content-inner">
				<p className="question-title">Veistu svarið?</p>
				<h2>{question.spurning}</h2>
				<p className="button-list">
					{
						question.svor.map((answer, index) =>
							<button key={index} className={'info-button'+(this.state.answerIndex == index ? ' active' : '')} onClick={() => {
								this.evaluateAnswer(answer, index);
							}}>{this.questionLetters[index]}{'\u00A0'}{'\u00A0'}{answer.svar}</button>
						)
					}
				</p>
				{
					this.state.message &&
					<div className="answer">
						{this.questionLetters[this.state.answerIndex]+' er '+(this.state.rightAnswer ? 'RÉTT' : 'RANGT')+' SVAR — '+this.state.message}
					</div>
				}
			</div>

			<div className="chapter-divider"
				style={{
					backgroundImage: dividerBackground
				}}
			/>

		</div>
	}
}


export default Spurningar;
