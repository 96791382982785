import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import ChapterItem from './ChapterItem';
import Modal from './Modal';
import Spurningar from './Spurningar';
import helper from './../helper';
import config from './../config';

import ScrollContainer from 'react-indiana-drag-scroll'

import prevButtonIcon from '../img/chapter-prev-button.svg';
import nextButtonIcon from '../img/chapter-next-button.svg';

class TimelineChapter extends React.Component {
	constructor(props) {
		super(props);

		this.scrollContainer = React.createRef();
		this.spurningarContainer = React.createRef();

		this.state = {
			data: []
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.kafli && (this.props.kafli != prevProps.kafli)) {
			this.fetchData();

			this.setState({
				modalData: null
			});
		}
	}

	fetchData() {
		fetch(config.apiRoot+'/api/timalina/atburdir/?kafli='+this.props.kafli)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					data: json.results
				});
			});

		fetch(config.apiRoot+'/api/timalina/kaflar?kafli='+this.props.kafli)
			.then((result) => result.json())
			.then((json) => {
				if (json.results && json.results.length == 1) {
					this.setState({
						chapterData: json.results[0]
					}, () => {
						this.scrollContainer.current.getElement().scrollTop = 0;
						this.scrollContainer.current.getElement().scrollLeft = 0;
					});
				}
			});
	}

	render() {
		let baseColor = '#503D32';

		let ContainerElement = 'div';

		ContainerElement = ScrollContainer;

		return <React.Fragment>

			{
				this.state.modalData &&
				<Modal data={this.state.modalData} onClose={() => {
					this.setState({
						modalData: null
					});
				}} />
			}

			<div className="scroll-wrapper">
				{
					this.state.chapterData &&
					<ContainerElement ref={this.scrollContainer} horizontal={true} vertical={false} className={'scroll-container'+(this.state.chapterData.efnissida ? ' page-content' : '')} id="scrollContainer">

						<div className={'scroll-section app-content chapter chapter-info'+(this.state.chapterData.mynd ? ' with-image' : '')}
							style={{
								color: this.state.chapterData.einkennislitur_dark || baseColor
							}}
						>
							<div className="app-content-inner">
								{
									(this.state.chapterData.prev_chapter || this.state.chapterData.next_chapter) && !this.state.chapterData.efnissida &&
									<div className="chapter-nav-buttons">
										{
											this.state.chapterData.prev_chapter &&
											<Link to={'/timalina/'+this.state.chapterData.prev_chapter.url} alt={this.state.chapterData.prev_chapter.nafn}><img src={prevButtonIcon} /></Link>
										}
										{
											this.state.chapterData.next_chapter &&
											<Link to={'/timalina/'+this.state.chapterData.next_chapter.url} alt={this.state.chapterData.next_chapter.nafn}><img src={nextButtonIcon} /></Link>
										}
									</div>
								}

								<h2>
									{
										this.state.chapterData.upphaf > 0 && this.state.chapterData.endir > 0 && !this.state.chapterData.efnissida &&
										<>
											<small>{this.state.chapterData.upphaf+'–'+this.state.chapterData.endir}</small><br/>
										</>
									}
									{this.state.chapterData.nafn}
								</h2>

								<p>{this.state.chapterData.texti}</p>

								{
									!this.state.chapterData.efnissida &&
									<p className="button-list">
										<button className="info-button icon icon-arrows" onClick={() => {
											this.scrollContainer.current.getElement().scrollBy({left: 1070, behavior: 'smooth'})
										}}>Tímaás</button>
										{
											this.state.chapterData.spurningar && this.state.chapterData.spurningar.length > 0 &&
											<button className="info-button icon icon-arrows" onClick={() => {
												if (helper.isMobile()) {
													document.getElementById('mainWrapper').scrollBy({top: this.spurningarContainer.current.element.current.offsetTop+20, behavior: 'smooth'})
												}
												else {
													this.scrollContainer.current.getElement().scrollBy({left: this.spurningarContainer.current.element.current.offsetLeft+20, behavior: 'smooth'})
												}
											}}>Spurningar</button>
										}
									</p>
								}

							</div>

							{
								this.state.chapterData.mynd &&
								<div className="chapter-info-image">
									<img src={config.siteRoot+'/media/'+this.state.chapterData.mynd} />
								</div>
							}

							<div className="chapter-divider desktop"
								style={{
									backgroundImage: "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 19.628115 132.29166'><g transform='matrix(0.36765613,0,0,0.36765613,-0.00652794,23.173863)'><path d='m 47.989544,-64.203783 -0.19521,15.624287 -5.60976,26.192412 -2.937,26.4596851 -6.14471,22.1833699 12.03084,33.408689 -2.66965,27.261491 3.74391,19.243409 -20.04079,39.55589 -0.80027,13.89801 -5.34311,20.31249 2.67499,20.57975 -4.80532,49.44486 -9.3520398,21.64884 -8.32527001,25.40841 H 53.402804 l 0.002,-361.221593 z' fill='"+(this.state.chapterData.einkennislitur_dark || baseColor).replace('#', '%23')+"' /></g></svg>\")"
								}}
							/>

							<div className="chapter-divider mobile"
								style={{
									backgroundImage: "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 132.29167 19.843749'><g transform='matrix(0,0.36765613,0.36765613,0,23.604914,0.20914619)'><path d='m 47.989544,-64.203783 -0.19521,15.624287 -5.60976,26.192412 -2.937,26.4596851 -6.14471,22.1833699 12.03084,33.408689 -2.66965,27.261491 3.74391,19.243409 -20.04079,39.55589 -0.80027,13.89801 -5.34311,20.31249 2.67499,20.57975 -4.80532,49.44486 -9.3520398,21.64884 -8.32527001,25.40841 H 53.402804 l 0.002,-361.221593 z' fill='"+(this.state.chapterData.einkennislitur_dark || baseColor).replace('#', '%23')+"' /></g></svg>\")"
								}}
							/>
						</div>

						{/* Einstakir atburðir kaflans */}
						{
							this.state.chapterData && this.state.data.length > 0 &&
							<span className="chapters-wrapper" style={{
								backgroundColor: this.state.chapterData.einkennislitur_dark || baseColor
							}}>

								{
									this.state.data.map((item, index) =>
										<ChapterItem key={index}
											index={index}
											item={item}
											firstItem={index == 0}
											lastItem={index == this.state.data.length-1}
											chapterData={this.state.chapterData}
											onModalData={(data) => {
												this.setState({
													modalData: data
												});
											}}
										/>
									)
								}
							</span>
						}

						{
							this.state.chapterData && this.state.chapterData.spurningar && this.state.chapterData.spurningar.length > 0 &&
							<Spurningar ref={this.spurningarContainer} chapterData={this.state.chapterData} />
						}

						{
							this.state.chapterData && this.state.chapterData.next_chapter && !this.state.chapterData.efnissida &&
							<div className="scroll-section app-content chapter"
								style={{
									color: this.state.chapterData.next_chapter.einkennislitur_dark || baseColor
								}}
							>
								<div className="app-content-inner">
									<h2>Næsti kafli, {this.state.chapterData.next_chapter.nafn}</h2>
									<p>{this.state.chapterData.next_chapter.texti}</p>
									<p className="button-list"><Link className="info-button icon icon-arrows" to={'/timalina/'+this.state.chapterData.next_chapter.url}>Áfram</Link></p>
								</div>
							</div>
						}
					</ContainerElement>
				}
			</div>

			{/*
				this.state.chapterData && this.state.chapterData.next_chapter &&
				<div className="next-chapter">
					<TimelineChapter kafli={this.state.chapterData.next_chapter.url} />
				</div>
			*/}

		</React.Fragment>;
	}
}


export default TimelineChapter;
