import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import _ from 'underscore';

import config from './../config';

function ChapterLink(props) {
	const [hovered, setHovered] = useState(false);

	return <Link to={'/timalina/'+props.item.url}
		onMouseOver={() => setHovered(true)}
		onMouseOut={() => setHovered(false)}
	>
		<small style={{
			color: hovered ? props.item.einkennislitur_bright || '#503D32' : '#503D32'
		}}>{props.item.upphaf+'–'+props.item.endir}</small>

		<h1 style={{
			transform: hovered ? 'translateX(16px)' : 'translateX(0)',
			color: hovered ? props.item.einkennislitur_bright || '#503D32' : '#503D32'
		}}>
			{props.item.nafn}
		</h1>

	</Link>;
}

function TimelineChapterList(props) {
	const [data, setData] = useState([]);
	const [imageData, setImageData] = useState([]);
	const [hoverImage, setHoverImage] = useState(null);

	useEffect(() => {
		if (data.length == 0) {
			fetch(config.apiRoot+'/api/timalina/kaflar/')
				.then((result) => result.json())
				.then((json) => {
					let chaptersData = _.where(json.results, {efnissida: false});

					setData(chaptersData);

					let images = _.compact(_.map(chaptersData, (item) => {
						if (item.mynd) {
							return {
								mynd: item.mynd,
								url: item.url
							};
						}
					}));

					setImageData(images);
				});
		}
	});

	return <>
		<div className="app-content-inner align-top">
			<div className="link-list">
				{
					data && data.length > 0 &&
					data.map((item, index) => <div key={index} onMouseOver={() => {
						if (item.mynd) {
							setHoverImage(item.url);
						}
					}} onMouseOut={() => {
						setHoverImage(null);
					}}>
						<ChapterLink item={item} />
					</div>)
				}
			</div>
		</div>

		{
			imageData && imageData.map((item, index) => <div key={index}
				className={'chapter-hover-image'+(hoverImage == item.url ? ' visible' : '')}
			>
				<img src={config.siteRoot+'/media/'+item.mynd} />
			</div>)
		}

	</>;
}


export default TimelineChapterList;
