import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";

import DividerLine from './DividerLine';
import _ from 'underscore';

import config from '../config';
import helper from '../helper';

function ChapterItem(props) {
	const element = useRef();
	const [containerHeight, setContainerHeight] = useState(null);

	const formatText = (str) => {
		return str.split('\n').map((line, index) => {
			return <React.Fragment key={index}>
				{
					index > 0 &&
					<br/>
				}
				<span dangerouslySetInnerHTML={{__html: line}} />
			</React.Fragment>
		})
	}

	const updateElementSize = () => {
		setContainerHeight(null);

		setTimeout(() => {
			let elementHeight = Math.ceil(element.current.clientHeight/gridSize)*gridSize;

			setContainerHeight(elementHeight);
		}, 100);

		let gridSize = 72;

	}

	useEffect(() => {
		updateElementSize();
	}, [props.item]);

	const baseColor = '#503D32';
	const baseColorBright = '#F7F5EB';

	return <div ref={element} className={'scroll-section app-content chapter'+
		(props.item.inngangur ? ' chapter-intro' : ' timeline-item')+
		(props.firstItem ? ' first' : '')+
		(props.lastItem ? ' last' : '')+
		(props.item.fela_artal ? ' hide-date' : '')}
		style={{
			height: containerHeight
		}}
	>

		<div className={'chapter-background'+(props.index == 0 ? ' first-chapter' : '')}
			style={{
				backgroundColor: props.index > 0 && props.index < 3 ? props.chapterData.einkennislitur_medium || baseColor : props.chapterData.einkennislitur_dark || baseColor
			}}
		/>

		{
			props.index == 2 &&
			<DividerLine color={props.chapterData.einkennislitur_medium || baseColor} />
		}

		{
			props.index == 0 &&
			<DividerLine color={props.chapterData.einkennislitur_dark || baseColor} />
		}

		{
			props.lastItem &&
			<DividerLine color={props.chapterData.einkennislitur_dark || baseColor} />
		}

		<div className={'app-content-inner'}>
			{
				!props.item.inngangur && props.item.artal && !props.item.fela_artal &&
				<div className="date"><span>{props.item.artal}</span></div>
			}
			{
				props.item.titill && !props.item.inngangur &&
				<h2 dangerouslySetInnerHTML={{__html: '<span style="color: '+(props.chapterData.einkennislitur_bright || baseColorBright)+'" class="capital-letter">'+(props.item.fela_titil ? '&nbsp;' : props.item.titill.charAt(0))+'</span>'+(props.item.fela_titil ? '&nbsp;' : props.item.titill.substring(1))}} />
			}
			<p>
				{
					props.item.inngangur &&
					<span style={{
						color: props.chapterData.einkennislitur_bright || baseColor
					}} className="quotation-mark first">„</span>
				}
				{formatText(props.item.texti)}
				{
					props.item.inngangur &&
					<span style={{
						color: props.chapterData.einkennislitur_bright || baseColor
					}} className="quotation-mark last">“</span>
				}
			</p>

			{
				props.item.aukaefni && props.item.aukaefni.length > 0 &&
				<p className="button-list">
					{
						props.item.aukaefni.map((item, index) =>
							<button key={index} className="info-button" onClick={() => {
								if (props.onModalData) {
									props.onModalData(item);
								}
							}}>{item.titill_a_takka || item.titill}</button>
						)
					}
				</p>
			}

			{
				props.item.myndefni && _.filter(props.item.myndefni, (item) => item.stadsetning == 'midja').length > 0 &&
				_.filter(props.item.myndefni, (item) => item.stadsetning == 'midja').map((mynd, index) =>
					<div key={index} className={'chapter-image position-'+(mynd.stadsetning)+(mynd.rammi ? ' image-frame' : '')}
						style={{
							transform: mynd.rammi ? 'rotate('+((Math.random()*10)-5)+'deg)' : null
						}}
					>
						<img src={config.siteRoot+mynd.myndaskra} />
					</div>
				)
			}

			{
				props.item.innskot &&
				<p className="innskot">{formatText(props.item.innskot)}</p>
			}
		</div>

		{
			props.item.myndefni && _.filter(props.item.myndefni, (item) => item.stadsetning != 'midja').length > 0 &&
			_.filter(props.item.myndefni, (item) => item.stadsetning != 'midja').map((mynd, index) =>
				<div key={index} className={'chapter-image position-'+(mynd.stadsetning)}>
					<img src={config.siteRoot+mynd.myndaskra} />
				</div>
			)
		}
	</div>;
}

export default ChapterItem;
