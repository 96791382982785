import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SiteFooter from './SiteFooter';

import config from '../config';

function Frontpage() {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (data.length == 0) {
			fetch(config.apiRoot+'/api/forsida/')
				.then((result) => result.json())
				.then((json) => {
					setData(json.results);
				});
		}

	});

	return (
		<>
			<div className="col three-fifth app-content app-front content-column front-image">

				<div className="app-content-inner">
					<h1><span className="firstletter color-red">F</span>orníslenskt hugverk og handverk</h1>
					<p>Handritin til barnanna fjallar um íslensk miðaldahandrit og þann vitnisburð sem þau geyma um fornt handverk, sagnaarf og hugðarefni fólks frá fyrri öldum.</p>
					<p>Vefurinn er lauslega byggður á vefnum <a href="https://handritinheima.is/">Handritin heima</a>.</p>

					{
						data && data.length > 0 &&
						<div className="front-boxes">
							{
								data.map((item, index) => {
									if (item.url.toLowerCase().startsWith('http') || item.url.toLowerCase().startsWith('https')) {
										return <a key={index}
											href={item.url}
											className={'box box-'+item.size}
											style={{
												backgroundImage: 'url('+config.siteRoot+'/media/'+item.mynd+')'
											}}
										>
											<span>{item.texti}</span>
										</a>
									}
									else {
										return <Link key={index}
											to={item.url}
											className={'box box-'+item.size}
											style={{
												backgroundImage: 'url('+config.siteRoot+'/media/'+item.mynd+')'
											}}
										>
											<span>{item.texti}</span>
										</Link>
									}
								})
							}
						</div>
					}
				</div>

			</div>
		</>
	);
}

export default Frontpage;
