import React, { useState } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import AppHeader from './components/AppHeader';

import Frontpage from './components/Frontpage';
import TimelineChapterList from './components/TimelineChapterList';
import TimelineChapter from './components/TimelineChapter';
import SubpageMenuButtons from './components/SubpageMenuButtons';
import PageView from './components/PageView';
import SiteFooter from './components/SiteFooter';

import './css/normalize.css';
//import './css/skeleton.css';
//import './css/bootstrap.min.css';
import './css/style.css';

import logoWhite from './img/logo-white.svg';
import logoText from './img/logo-text-handritin-til-barnanna-white.svg';

class App extends React.Component {
	constructor(props) {
		super(props);

		this.appContainer = React.createRef();

		this.state = {
			isFront: true,
			menuVisible: false
		};
	}

	componentDidMount() {
		this.refs.router.history.listen(function(event) {
			try {
				this.appContainer.current.scrollTop = 0;
			}
			catch {}

			this.setState({
				routePath: event.pathname,
				isFront: event.pathname == '/' || event.pathname.indexOf('/sida/') != -1,
				isSubpage: event.pathname.match(/\/([^\/]+)\/([^\/]+)/) && event.pathname.indexOf('/sida/') == -1,
				menuVisible: false
			});

			window.scrollTo(0, 0)
		}.bind(this));

		this.setState({
			isFront: this.refs.router.history.location.pathname == '/' || this.refs.router.history.location.pathname.indexOf('/sida/') != -1,
			isSubpage: this.refs.router.history.location.pathname.match(/\/([^\/]+)\/([^\/]+)/) && this.refs.router.history.location.pathname.indexOf('/sida/') == -1
		});
	}

	render() {
		return (
			<div className={'app'+(this.state.isFront ? ' front' : '')+(this.state.isSubpage ? ' sub' : '')+(this.state.menuVisible ? ' menu-visible' : '')}>

				<header className="app-header mobile-header">
					<a href="/" className={'app-logo'}>
						<img src={logoWhite} className="logo" />
						<img src={logoText} className="text" alt="logo" />
					</a>
				</header>

				<Router ref="router">

					<div className="app-container menu-wrapper row">

						<AppHeader largeLogo={!this.state.isFront} />

						<Switch>
							<Route path="/timalina">
								<div className="col three-fifth app-content content-column">
									<TimelineChapterList />
								</div>

								<Link className="front-menu-button-mobile" to={'/'}>
									<span>Forsíða</span>
								</Link>

								<a className="chapter-menu-button" onClick={(event) => {
									event.preventDefault();

									this.setState({
										menuVisible: !this.state.menuVisible
									});
								}}><span>Forsíða</span></a>
							</Route>
							<Route exact path="/">
								<Frontpage />

								<Link className="chapter-menu-button" onClick={(event) => {
									event.preventDefault();

									this.setState({
										menuVisible: !this.state.menuVisible
									});
								}}><span>Forsíða</span></Link>
							</Route>
							<Route exact path="/sida/:sida" render={(routeProps) =>
								<>
									<PageView {...routeProps} />

									<Link className="chapter-menu-button" onClick={(event) => {
										event.preventDefault();

										this.setState({
											menuVisible: !this.state.menuVisible
										});
									}}><span>Forsíða</span></Link>
								</>
							} />
						</Switch>

						<SiteFooter className="mobile-footer" />

					</div>

					<div ref={this.appContainer} id="mainWrapper" className="app-container main-wrapper horizontal-scroll">
						<Switch>
							<Route exact path="/timalina/:kafli?" render={(routeProps) =>
								<React.Fragment>
									<SubpageMenuButtons chapterLink={'/timalina'} chapterButtonLabel={'Tímalína'} />
									<TimelineChapter kafli={routeProps.match.params.kafli} {...routeProps} />
								</React.Fragment>
							} />
							<Route exact path="/handrit/:kafli?" render={(routeProps) =>
								<React.Fragment>
									<SubpageMenuButtons chapterLink={'/'} chapterButtonLabel={'Forsíða'} />
									<TimelineChapter kafli={routeProps.match.params.kafli} {...routeProps} />
								</React.Fragment>
							} />
							<Route exact path="/efni/:kafli?" render={(routeProps) =>
								<React.Fragment>
									<SubpageMenuButtons chapterLink={'/'} chapterButtonLabel={'Forsíða'} />
									<TimelineChapter kafli={routeProps.match.params.kafli} {...routeProps} />
								</React.Fragment>
							} />
						</Switch>

						<SiteFooter className="mobile-footer" />

					</div>

				</Router>

			</div>
		);
	}
}

export default App;
