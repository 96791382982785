export default {
	siteTitle: 'Handritin til barnanna',
	pageSize: 50,

//	apiRoot: 'http://130.208.178.119:8000',
	apiRoot: '/django',

//	siteRoot: 'http://130.208.178.119:8000',
	siteRoot: '/django',
//	siteRoot: 'http://handritinheima.is/django/',

	gaTrackingId: ''
}
