export default {
	isMobile: () => {
		if (window.document) {
			let win = window;
			let doc = document;
			let docElem = doc.documentElement;
			let body = doc.getElementsByTagName('body')[0];

			return (win.innerWidth || docElem.clientWidth || body.clientWidth) < 1100;
		}
		else {
			return false;
		}
	}
}
