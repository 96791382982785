import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import SiteFooter from './SiteFooter';

import logo from '../img/logo-brown.svg';
import logoWhite from '../img/logo-white.svg';
import logoText from '../img/logo-text-handritin-til-barnanna-brown.svg';

function AppHeader(props) {
	const [mobileHeader, setMobileHeader] = useState(false);

	useEffect(() => {
		if (window) {
			setMobileHeader(window.innerWidth < 700);
		}
	});

	return (
		<div className={'col two-fifth app-content menu-column'}>
			<header className="app-header">
				<NavLink to="/" className={'app-logo'+(props.largeLogo ? ' large' : '')}>
					<img src={props.largeLogo || mobileHeader ? logoWhite : logo} className="logo" />
					<img src={logoText} className="text" alt="logo" />
				</NavLink>
			</header>

			<div className="app-content-inner">
				<ul className="front-menu">
					{/*<li><NavLink exact to="/personur">Sögupersónur</NavLink></li>*/}
					<li><NavLink exact to="/timalina">Tímaás</NavLink></li>
					<li><NavLink exact to="/efni/handritin">Handritin</NavLink></li>
				</ul>
			</div>

			<SiteFooter />
		</div>
	);
}

export default withRouter(AppHeader);
