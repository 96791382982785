import React, {useState, useEffect} from 'react';

import closeButtonIcon from '../img/close-button.svg';
import config from '../config';

function Modal(props) {
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setInitialized(true);
		}, 100);
	});

	return (
		<React.Fragment>
			<div className={'modal-overlay'+(initialized ? ' initialized' : '')} onClick={() => {
				if (props.onClose) {
					setInitialized(false);

					setTimeout(() => {
						props.onClose();
					}, 100);
				}
			}} />

			<div className={'modal'+(initialized ? ' initialized' : '')}>

				<button className="close-button" onClick={() => {
					if (props.onClose) {
						setInitialized(false);

						setTimeout(() => {
							props.onClose();
						}, 100);
					}
				}}><img src={closeButtonIcon} /></button>

				<div className="modal-image">
					{
						props.data.mynd &&
						<img src={config.siteRoot+props.data.mynd} />
					}
					{
						props.data.handrit_embed_url &&
						<iframe frameborder="0" src={props.data.handrit_embed_url} width="100%" height="700" />
					}
				</div>

				<div className="modal-content">
					<h3>
						{
							props.data.artal &&
							<>
								<small>{props.data.artal}</small><br/>
							</>
						}
						{props.data.titill}
					</h3>

					<p>{props.data.texti.split('\n').map((line, index) => {
						return <React.Fragment>
							{
								index > 0 &&
								<br/>
							}
							<span dangerouslySetInnerHTML={{__html: line}} />
						</React.Fragment>
					})}</p>
				</div>

			</div>
		</React.Fragment>
	);
}

export default Modal;
